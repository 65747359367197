import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// Thunk to fetch image types
export const fetchImageType = createAsyncThunk(
  'image/fetchImageType',
  async (_) => {
    try {
      const response = await axios.get(`${BACKEND_URL}/api/util/image-type`);
      console.log('imageType: ', response.data.image_type);
      return response.data.image_type;
    } catch (error) {
      console.log(error.message);
      return error.message;
    }
  }
);

// Thunk to fetch all images
export const fetchAllImages = createAsyncThunk(
  'image/fetchAllImages',
  async ({ typeId = null } = {}) => {
    try {
      const token = localStorage.getItem('auth_token');
      const response = await axios.get(`${BACKEND_URL}/api/user-getimage`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });
      return response.data.images;
    } catch (error) {
      console.log(error.message);
      return error.message;
    }
  }
);

// Thunk to upload image
export const uploadImage = createAsyncThunk(
  'image/uploadImage',
  async ({ image, typeId }) => {
    console.log('Image received in uploadImage thunk:', image);
    console.log('TypeId received:', typeId);

    try {
      const formData = new FormData();
      formData.append('image', image);
      formData.append('type', typeId);

      console.log('formData: ', formData);

      const token = localStorage.getItem('auth_token');
      const response = await axios.post(
        `${BACKEND_URL}/api/upload-image`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        }
      );
      console.log('API Response:', response.data);
      const { id, type, url } = response?.data;
      return { id, type, url: '/' + url };
    } catch (error) {
      console.log(error.message);

      return error.message;
    }
  }
);

// Image slice
const imageSlice = createSlice({
  name: 'image',
  initialState: {
    images: [],
    imageTypes: [],
    horoscopeImages: [],
    photoAlbumImages: [],
    profilePhotoImages: [],
    companyLogoImages: [],
    loading: false,
    error: null,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
      // fetchImageType handling
      .addCase(fetchImageType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchImageType.fulfilled, (state, action) => {
        state.loading = false;
        state.imageTypes = action.payload; // Consistent naming here
      })
      .addCase(fetchImageType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // fetchAllImages handling
      .addCase(fetchAllImages.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllImages.fulfilled, (state, action) => {
        state.loading = false;
        const allImages = Array.isArray(action.payload) ? action.payload : []; // Ensure it's an array
        state.images = allImages;

        // Extract IDs based on image types, handle if not found
        const horoscopeTypeId = state.imageTypes.find(
          (type) => type.image_type === 'Horoscope'
        )?.id;
        const profilePhotoTypeId = state.imageTypes.find(
          (type) => type.image_type === 'Profile Photo'
        )?.id;
        const companyLogoTypeId = state.imageTypes.find(
          (type) => type.image_type === 'Company Logo'
        )?.id;
        const photoAlbumTypeId = state.imageTypes.find(
          (type) => type.image_type === 'Photo Album'
        )?.id;

        // Safeguard array operations
        state.horoscopeImages = Array.isArray(allImages)
          ? allImages
              .filter((image) => String(image.type) === String(horoscopeTypeId))
              .sort((a, b) => b.id - a.id) // Sort by id in descending order
              .slice(0, 2) // Keep only the latest 2 images
          : [];
        state.profilePhotoImages = Array.isArray(allImages)
          ? allImages
              .filter(
                (image) => String(image.type) === String(profilePhotoTypeId)
              )
              .sort((a, b) => b.id - a.id)
              .slice(0, 1)
          : [];
        state.companyLogoImages = Array.isArray(allImages)
          ? allImages
              .filter(
                (image) => String(image.type) === String(companyLogoTypeId)
              )
              .sort((a, b) => b.id - a.id)
              .slice(0, 1)
          : [];

        const photoAlbumImages = Array.isArray(allImages)
          ? allImages
              .filter(
                (image) => String(image.type) === String(photoAlbumTypeId)
              )
              .sort((a, b) => b.id - a.id)
          : [];
        state.photoAlbumImages = photoAlbumImages.slice(0, 4);
      })
      .addCase(fetchAllImages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // uploadImage handling
      .addCase(uploadImage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadImage.fulfilled, (state, action) => {
        state.loading = false;
        fetchAllImages();
        const newImage = action.payload;
        // console.log(newImage);
        state.images.push(newImage);
        // Match the image type to the appropriate image array
        const horoscopeTypeId = state.imageTypes.find(
          (type) => type.image_type === 'Horoscope'
        )?.id;
        const profilePhotoTypeId = state.imageTypes.find(
          (type) => type.image_type === 'Profile Photo'
        )?.id;
        const companyLogoTypeId = state.imageTypes.find(
          (type) => type.image_type === 'Company Logo'
        )?.id;
        const photoAlbumTypeId = state.imageTypes.find(
          (type) => type.image_type === 'Photo Album'
        )?.id;

        // Add the image to the corresponding array based on the type
        if (String(newImage.type) === String(horoscopeTypeId)) {
          state.horoscopeImages.push(newImage);
        } else if (String(newImage.type) === String(profilePhotoTypeId)) {
          // Replace the current profile photo with the new one
          state.profilePhotoImages = [newImage];
          // console.log('newImage,', newImage);
          // console.log('state.profilePhotoImages,', state.profilePhotoImages);
        } else if (String(newImage.type) === String(companyLogoTypeId)) {
          // Replace the current company logo with the new one
          state.companyLogoImages = [newImage];
        } else if (String(newImage.type) === String(photoAlbumTypeId)) {
          // Add to the photo album array
          state.photoAlbumImages.push(newImage);
          // If you want to limit the number of images in photoAlbumImages to 4
          state.photoAlbumImages = state.photoAlbumImages.slice(0, 4);
        }

        console.log('Uploaded image added to the correct array:', newImage);
      })
      .addCase(uploadImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default imageSlice.reducer;
