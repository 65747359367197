/* eslint-disable no-unused-vars */
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import Cropper from 'react-easy-crop';

import Button from '../../../../components/UI/Button/Button';
import { AiOutlineLoading } from 'react-icons/ai';
import { uploadImage } from '../../../../redux/features/imageSlice';
import {
  applyWatermark,
  getCompressedImage,
  getCroppedImg,
  handleCropComplete,
} from '../../../../utils/imageUtils';

const PhotoAlbumCard = ({ imageUrl, isEditable, imageId }) => {
  const dispatch = useDispatch();
  const [isLoding, setIsLoading] = useState(false);
  // const [fileName, setFileName] = useState('');
  const [profilePicUrl, setProfilePicUrl] = useState(imageUrl || '');
  const [selectedImage, setSelectedImage] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const handleProfilePicChange = async (e) => {
    let file = e.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];

    if (file && allowedTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.onload = () => setSelectedImage(reader.result);
      reader.readAsDataURL(file);
    } else {
      // setFileName('');
      setProfilePicUrl('');
    }
    setIsLoading(false);
  };

  const saveCroppedImage = async () => {
    setIsLoading(true);

    try {
      // Step 1: Crop the image
      const croppedImage = await getCroppedImg(
        selectedImage,
        crop,
        croppedAreaPixels
      );

      // Step 2: Apply watermark to the cropped image
      const watermarkedImage = await applyWatermark(croppedImage);

      // Step 3: Compress the image after adding the watermark
      const compressedImage = await getCompressedImage(watermarkedImage);
      // console.log(compressedImage.size / 1024 / 1024);

      // Step 4: Convert to Blob URL and set to state/form data
      const BLOB_URL = URL.createObjectURL(compressedImage);
      setProfilePicUrl(BLOB_URL);

      console.log(typeof BLOB_URL, 'dispach');

      // Reset cropper after saving
      dispatch(uploadImage({ image: compressedImage, typeId: 3 }));
      setSelectedImage(null);
    } catch (error) {
      console.error('Error cropping or processing image:', error);
    }
    setIsLoading(false);
  };
  return (
    <li
      className='flex flex-col justify-center items-center rounded-lg w-full md:w-[45%] lg:w-[22%] self-start lg:mx-4 my-6
    '
    >
      {selectedImage && (
        <div className='flex flex-col justify-center items-center relative py-5 p-4 w-[250px] h-[250px] md:w-[500px] md:h-[500px] lg:w-[700px] bg-white'>
          {/* <div className='flex flex-col justify-center items-center relative py-5 p-4 w-[250px] md:w-[500px] lg:w-[700px] h-[250px] md:h-[400px] bg-white'> */}
          <div className='cropper-containe'>
            <Cropper
              image={selectedImage}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={(croppedArea, croppedAreaPixels) =>
                handleCropComplete(croppedAreaPixels, setCroppedAreaPixels)
              }
            />
            <button
              className='flex justify-center items-center bg-green-500 lg:!text-[24px] lg:!font-bold  text-[#FFFFFF] border-2 px-4 py-1 absolute translate-x-[-50%] translate-y-[-50%] bottom-0 rounded-lg font-bold'
              onClick={saveCroppedImage}
            >
              Crop
              {isLoding && <AiOutlineLoading className='animate-spin ml-2' />}
            </button>
          </div>
        </div>
      )}
      <div
        className={`relative flex justify-center items-center w-full lg:min-w-full min-h-[200px] lg:max-w-full max-h-[200px] overflow-hidden rounded-t-xl border-2 border-dashed ${
          profilePicUrl
            ? 'border-b-transparent'
            : 'bg-[#F7F7F7] border-2 border-dashed border-[#CCCCCC]'
        }`}
      >
        {!profilePicUrl && (
          <span className='absolute flex justify-center items-center w-full h-full'>
            <label
              htmlFor='profilePic'
              className='flex justify-center items-center h-[45px] px-4 rounded-md font-bold text-white bg-[#FF4061CC] hover:bg-[#FF4061] cursor-pointer active:border-[#808080] border-2 border-[transparent]'
            >
              Add
            </label>
            <input
              id='profilePic'
              type='file'
              className='hidden'
              accept='image/*'
              onChange={handleProfilePicChange}
            />
          </span>
        )}
        {console.log('dc:', profilePicUrl)}

        {profilePicUrl && (
          <img
            src={profilePicUrl}
            alt='ablum'
            className='rounded-t-xl h-[200px] inset-0 object-cover w-full lg:w-full'
          />
        )}
      </div>
      {profilePicUrl && (
        <div className='flex justify-between items-center w-full'>
          <Button
            text='Change'
            className='!bg-[#333333] font-bold w-[45%] rounded-bl-xl rounded-br-3xl rounded-t-none'
          />
          <Button
            onButtonClick={() => {
              setProfilePicUrl(null);
            }}
            text='Remove'
            className='font-bold w-[45%] rounded-bl-3xl rounded-br-xl rounded-t-none'
          />
        </div>
      )}
    </li>
  );
};

export default PhotoAlbumCard;
