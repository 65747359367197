/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Button from '../../../../components/UI/Button/Button';
import Input from '../../../../components/UI/Input/Input';
import SelectInput from '../../../../components/UI/SelectInput/SelectInput';
import EditProfileLayout from './EditProfileLayout';
import { AiOutlineLoading } from 'react-icons/ai';
import { useSelector } from 'react-redux';

const AddressProfile = (props) => {
  const user = useSelector((state) => state.user.userInfo);
  const { city, state, country, pincode } = user?.additional_details || {};
  // console.log(city, state, country, pincode);
  const [statesArrayPresent, setStatesArrayPresent] = useState([]);
  const [cityArrayPresent, setCityArrayPresent] = useState([]);
  const [statesArrayFamily, setStatesArrayFamily] = useState([]);
  const [cityArrayFamily, setCityArrayFamily] = useState([]);

  const countryData = useSelector((state) => state.field.country);
  const statesData = useSelector((state) => state.field.states);
  const cityData = useSelector((state) => state.field.city);
  // console.log(countryData);
  // console.log(statesData);
  // console.log(cityData);

  const countryOptions = countryData.filter((option) => option.id !== country);
  const statesOptions = statesData.filter(
    (option) => option.stcode !== state?.toLowerCase()
  );
  const cityOptions = cityData.filter((option) => option.id !== city);
  // console.log('countryOptions: ', countryOptions);
  // console.log('statesOptions: ', statesOptions);
  // console.log('cityOptions: ', cityOptions);

  // console.log(countryData, statesData, cityData);

  const selectedCity =
    cityData && cityData.length > 0
      ? cityData.find((cityObj) => cityObj.id === city)?.name || ''
      : '';

  const selectedState =
    statesData && statesData.length > 0
      ? statesData.find(
          (stateObj) => stateObj.stcode.toLowerCase() === state?.toLowerCase()
        )?.name || ''
      : '';

  let selectedCountry =
    countryData && countryData.length > 0
      ? countryData.find((countryObj) => countryObj.id === country)?.name || ''
      : '';
  // console.log('selectedState: ', selectedState);

  const [isLoading, setIsLoading] = useState(false);
  const [presentAddress, setPresentAddress] = useState({
    country: country || '',
    state: state || '',
    city: city || '',
    pincode: pincode || '',
  });
  const [familyAddress, setFamilyAddress] = useState({
    country: '',
    state: '',
    city: '',
    pincode: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [sameAsAbove, setSameAsAbove] = useState(false);
  const [touched, setTouched] = useState({
    present: {},
    family: {},
  });

  useEffect(() => {
    setPresentAddress((prevData) => ({
      ...prevData,
      country: country,
      state: state || '',
      city: city || '',
      pincode: pincode || '',
    }));
  }, [country, state, city, pincode]);

  // let countryArray = countryData.map((country, idx) => {
  //   return { name: country.name, id: country.id };
  // });

  const validateField = (field, value) => {
    let error = '';
    switch (field) {
      case 'city':
        if (!value) {
          error = 'City is required.';
        }
        break;
      case 'state':
        if (!value) {
          error = 'State is required.';
        }
        break;
      case 'country':
        if (!value) {
          error = 'Country is required.';
        }
        break;
      case 'pincode':
        if (!value) {
          error = 'Pincode is required.';
        } else if (!/^\d{6}$/.test(value)) {
          error = 'Pincode should be a 6-digit number.';
        }
        break;
      default:
        break;
    }
    return error;
  };

  useEffect(() => {
    const errors = { ...formErrors };
    Object.keys(presentAddress).forEach((field) => {
      if (touched.present[field]) {
        errors[`present${field.charAt(0).toUpperCase() + field.slice(1)}`] =
          validateField(field, presentAddress[field]);
      }
    });
    if (!sameAsAbove) {
      Object.keys(familyAddress).forEach((field) => {
        if (touched.family[field]) {
          errors[`family${field.charAt(0).toUpperCase() + field.slice(1)}`] =
            validateField(field, familyAddress[field]);
        }
      });
    }
    setFormErrors(errors);
  }, [presentAddress, familyAddress, touched, sameAsAbove]);

  const handleAddressChange = (addressType, field, value) => {
    // console.log('value: ', value);

    const setAddress =
      addressType === 'present' ? setPresentAddress : setFamilyAddress;
    const addressStateKey = addressType === 'present' ? 'present' : 'family';

    if (field === 'country') {
      if (!value) {
        if (addressType === 'present') {
          setStatesArrayPresent(null);
          setCityArrayPresent(null);
        } else {
          setStatesArrayFamily(null);
          setCityArrayFamily(null);
        }
        setAddress((prevState) => ({
          ...prevState,
          state: '',
          city: '',
        }));
      }
      selectedCountry = countryData.find((country) => {
        return country.id === parseInt(value);
      });
      // console.log('selectedCountry: ', selectedCountry);

      if (selectedCountry) {
        const { country_code } = selectedCountry;
        // console.log('country_code', country_code);

        let newStatesArray = statesData.filter(
          (state) => state.country_code === country_code
        );
        // console.log(newStatesArray);
        newStatesArray = newStatesArray.map((state) => {
          const newState = { value: state.stcode, ...state };
          return newState;
        });

        // console.log(newStatesArray);
        if (addressType === 'present') {
          setStatesArrayPresent(newStatesArray);
        } else {
          setStatesArrayFamily(newStatesArray);
        }
      }
    }

    if (field === 'state') {
      // console.log(!value);
      if (!value) {
        // console.log(value);

        if (addressType === 'present') {
          setCityArrayPresent(null);
          setAddress((prevState) => ({
            ...prevState,
            city: '',
          }));
        } else {
          setCityArrayFamily(null);
          setAddress((prevState) => ({
            ...prevState,
            city: '',
          }));
        }
      }
      const selectedState = statesData.find((state) => state.stcode === value);
      // console.log('selectedState: ', selectedState);

      if (selectedState) {
        const { stcode } = selectedState;
        const newCityArray = cityData.filter((city) => city.stcode === stcode);
        // console.log('newCityArray: ', newCityArray);
        if (addressType === 'present') {
          setCityArrayPresent(newCityArray);
        } else {
          setCityArrayFamily(newCityArray);
        }
      }
    }

    setAddress((prevState) => ({ ...prevState, [field]: value }));
    setTouched((prevTouched) => ({
      ...prevTouched,
      [addressStateKey]: { ...prevTouched[addressStateKey], [field]: true },
    }));
  };

  const handleSameAsAboveChange = (e) => {
    console.log(isLoading);

    setSameAsAbove(e.target.checked);
    if (e.target.checked) {
      setFamilyAddress(presentAddress);
    }
  };

  const validateInputs = () => {
    const errors = {};
    let isValid = true;

    const validateAddress = (address, prefix) => {
      Object.keys(address).forEach((field) => {
        const error = validateField(field, address[field]);
        if (error) {
          errors[`${prefix}${field.charAt(0).toUpperCase() + field.slice(1)}`] =
            error;
          isValid = false;
        }
      });
    };

    validateAddress(presentAddress, 'present');

    if (!sameAsAbove) {
      validateAddress(familyAddress, 'family');
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleOnSave = () => {
    setIsLoading(true);
    console.log('save:');

    if (!validateInputs()) {
      // setIsLoading(false);
      return;
    }

    try {
      setTimeout(() => {
        setIsLoading(false);
        console.log('presentAddress: ', presentAddress);
        console.log('familyAddress: ', familyAddress);
      }, 1000);
      // props.onShowEditSuccess('Save!');
    } catch (err) {
      console.error(err.message);
      setIsLoading(false);
    } finally {
    }
  };

  return (
    <EditProfileLayout onShowSignUp={props.onShowSignUp} editPage='address'>
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <SectionTitle title='Present Address'>
          <AddressFields
            address={presentAddress}
            selectedCountry={selectedCountry}
            selectedState={selectedState}
            selectedCity={selectedCity}
            countryOptions={countryOptions}
            stateOptions={statesOptions}
            cityOptions={cityOptions}
            // stateOptions={statesArrayPresent}
            // cityOptions={cityArrayPresent}
            onAddressChange={(field, value) =>
              handleAddressChange('present', field, value)
            }
            errors={formErrors}
            prefix='present'
          />
        </SectionTitle>
        <SectionTitle title='Family Address'>
          <div className='flex justify-start items-center w-full'>
            <input
              type='checkbox'
              id='sameasabove'
              className='cursor-pointer'
              checked={sameAsAbove}
              onChange={handleSameAsAboveChange}
            />
            <label
              htmlFor='sameasabove'
              className='cursor-pointer font-medium text-[14px] ml-1 text-[#858585]'
            >
              Same as above
            </label>
          </div>
          {!sameAsAbove && (
            <AddressFields
              address={familyAddress}
              selectedCountry={''}
              selectedState={''}
              selectedCity={''}
              countryOptions={countryData}
              stateOptions={statesArrayFamily}
              cityOptions={cityArrayFamily}
              onAddressChange={(field, value) =>
                handleAddressChange('family', field, value)
              }
              errors={formErrors}
              prefix='family'
            />
          )}
        </SectionTitle>
        <div className='flex justify-start items-center my-5 w-full'>
          <Button
            onButtonClick={handleOnSave}
            text={isLoading ? 'Loading...' : 'Save'}
            className='font-bold w-[130px] h-[50px]'
          >
            {isLoading && <AiOutlineLoading className='animate-spin ml-2' />}
          </Button>
        </div>
      </div>
    </EditProfileLayout>
  );
};

const SectionTitle = ({ title, children }) => (
  <div className='flex flex-col justify-center items-center w-full h-full font-bold text-[18px] text-[#FF4061]'>
    <div className='flex justify-start items-center w-full font-bold text-[18px] text-[#FF4061]'>
      {title}
    </div>
    {children}
  </div>
);

const AddressFields = ({
  address,
  countryOptions,
  stateOptions,
  cityOptions,
  selectedCountry,
  selectedState,
  selectedCity,
  onAddressChange,
  errors,
  prefix,
}) => (
  <div className='w-full'>
    <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full'>
      <div className='flex justify-center items-center w-full md:w-[49%]'>
        <SelectInput
          id={`${prefix}Country`}
          label='Country:'
          options={countryOptions}
          className='font-semibold cursor-pointer'
          width='w-full'
          value={address.country}
          selectType='id'
          select={selectedCountry || ''}
          onChange={(e) => onAddressChange('country', e.target.value)}
          error={errors[`${prefix}Country`]}
        />
      </div>
      <div className='flex justify-center items-center w-full md:w-[49%]'>
        <SelectInput
          id={`${prefix}State`}
          label='State:'
          options={stateOptions}
          selectType='value'
          className='font-semibold cursor-pointer'
          width='w-full'
          value={address.state}
          select={selectedState || ''}
          onChange={(e) => onAddressChange('state', e.target.value)}
          error={errors[`${prefix}State`]}
        />
      </div>
    </div>
    <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full'>
      <div className='flex justify-center items-center w-full md:w-[49%]'>
        <SelectInput
          id={`${prefix}City`}
          name='city'
          label='City:'
          selectType='id'
          options={cityOptions}
          className='w-full'
          value={address.city}
          selectValue='2'
          select={selectedCity || ''}
          onChange={(e) => onAddressChange('city', e.target.value)}
          error={errors[`${prefix}City`]}
        />
      </div>
      <div className='flex justify-center items-center w-full md:w-[49%]'>
        <Input
          id={`${prefix}Pincode`}
          label='Pincode:'
          placeholder='Enter pincode'
          className=''
          width='w-full'
          value={address.pincode}
          onChange={(e) => onAddressChange('pincode', e.target.value)}
          error={errors[`${prefix}Pincode`]}
        />
      </div>
    </div>
  </div>
);

export default AddressProfile;
