import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cropper from 'react-easy-crop';
import { MdOutlineModeEdit } from 'react-icons/md';
import { AiOutlineLoading } from 'react-icons/ai';
import { uploadImage } from '../../../../redux/features/imageSlice';
import {
  applyWatermark,
  getCompressedImage,
  getCroppedImg,
  handleCropComplete,
} from '../../../../utils/imageUtils';

const PersonalProfileImageProcessing = ({ formErrors, setFormErrors }) => {
  const images = useSelector((state) => state.image.profilePhotoImages);
  let img = images && images[0] && images[0].url;
  const fullImageUrl = img ? 'https://api.dev.sanroj.com' + img : '';
  // console.log('profilePhotoImages', images);

  useEffect(() => {
    if (fullImageUrl) {
      setProfilePicUrl(fullImageUrl);
    }
  }, [fullImageUrl]);

  const dispatch = useDispatch();
  const [isLoding, setIsLoading] = useState(false);
  const [profilePicUrl, setProfilePicUrl] = useState(fullImageUrl);
  const [selectedImage, setSelectedImage] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const handleProfilePicChange = async (e) => {
    let file = e.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];

    if (file && allowedTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.onload = () => setSelectedImage(reader.result);
      reader.readAsDataURL(file);
    } else {
      setProfilePicUrl('');
      setFormErrors((prevState) => ({
        ...prevState,
        profilePic:
          'Invalid file type. Please select an image file (jpeg, png, gif).',
      }));
    }
    setIsLoading(false);
  };

  const saveCroppedImage = async () => {
    setIsLoading(true);
    try {
      // Step 1: Crop the image
      const croppedImage = await getCroppedImg(
        selectedImage,
        crop,
        croppedAreaPixels
      );

      // Step 2: Apply watermark to the cropped image
      const watermarkedImage = await applyWatermark(croppedImage);

      // Step 3: Compress the image after adding the watermark
      const compressedImage = await getCompressedImage(watermarkedImage);
      console.log('compressedImage: ', compressedImage.size / 1024 / 1024);

      // Step 4: Convert to Blob URL and set to state/form data
      const BLOB_URL = URL.createObjectURL(compressedImage);
      setProfilePicUrl(BLOB_URL);

      // Reset cropper after saving
      dispatch(uploadImage({ image: compressedImage, typeId: 2 }));
      setSelectedImage(null);
    } catch (error) {
      console.error('Error cropping or processing image:', error);
    }
    setIsLoading(false);
  };

  return (
    <div className='flex justify-around items-center w-full'>
      <div className='flex flex-col justify-center items-center'>
        {selectedImage && (
          <div className='flex flex-col justify-center items-center relative py-5 p-4 w-[250px] md:w-[500px] lg:w-[700px] h-[250px] md:h-[400px] bg-white'>
            <div className='cropper-containe'>
              <Cropper
                image={selectedImage}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={(croppedArea, croppedAreaPixels) =>
                  handleCropComplete(croppedAreaPixels, setCroppedAreaPixels)
                }
              />
              <button
                className='flex justify-center items-center bg-green-500 lg:!text-[24px] lg:!font-bold  text-[#FFFFFF] border-2 px-4 py-1 absolute translate-x-[-50%] translate-y-[-50%] bottom-0 rounded-lg font-bold'
                onClick={saveCroppedImage}
              >
                Crop
                {isLoding && <AiOutlineLoading className='animate-spin ml-2' />}
              </button>
            </div>
          </div>
        )}
        <div className='flex justify-center items-center'>
          <label htmlFor='profilePic'>
            <span
              className={`flex justify-center items-center outline-dashed outline-2 ${
                formErrors.profilePic
                  ? 'outline-[#FF4061]'
                  : 'outline-[#9CA3AF]'
              } rounded-full w-[100px] h-[100px] cursor-pointer relative bg-[#F4F4F4]`}
            >
              <div className='flex justify-center items-center w-full h-full rounded-full overflow-hidden relative'>
                {profilePicUrl && (
                  <img
                    src={profilePicUrl}
                    alt='SelectedProfilePic'
                    className='absolute h-full max-w-none inset-0 object-cover'
                  />
                )}
                {/* {console.log('profilePicUrl: ', profilePicUrl)} */}
              </div>
              <MdOutlineModeEdit className='text-[#FFFFFF] rounded-full w-[24px] h-[24px] p-1 bg-[#FF4061] absolute bottom-1 right-1' />
            </span>
          </label>
          <input
            id='profilePic'
            type='file'
            className='hidden'
            accept='image/*'
            onChange={handleProfilePicChange}
          />
        </div>
        {/* {fileName && (
          <div className='text-[#9CA3AF] px-2 text-sm mt-1 border-2'>
            {fileName}
          </div>
        )} */}
      </div>
    </div>
  );
};

export default PersonalProfileImageProcessing;
