import { useEffect, useState } from "react";
import Button from "../../../../components/UI/Button/Button";
import EditProfileLayout from "./EditProfileLayout";
import { AiOutlineLoading } from "react-icons/ai";

const numberWords = [
  "zero",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
  "ten",
  "eleven",
  "twelve",
  "thirteen",
  "fourteen",
  "fifteen",
  "sixteen",
  "seventeen",
  "eighteen",
  "nineteen",
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
  "hundred",
  "thousand",
  "million",
  "billion",
];
const emailWords = [
  "at",
  "dot",
  "com",
  "net",
  "org",
  "edu",
  "gov",
  "mil",
  "co",
  "uk",
  "de",
  "jp",
  "fr",
  "au",
  "br",
  "it",
  "us",
  "ca",
  "cn",
  "ru",
  "info",
  "biz",
  "me",
  "name",
  "tv",
  "cc",
  "us",
  "ws",
  "mobi",
  "tel",
  "asia",
  "jobs",
  "cat",
  "pro",
  "aero",
  "coop",
  "museum",
];
const addressWords = [
  "street",
  "st",
  "road",
  "rd",
  "lane",
  "ln",
  "avenue",
  "ave",
  "boulevard",
  "blvd",
  "drive",
  "dr",
  "court",
  "ct",
  "circle",
  "cir",
  "place",
  "pl",
  "apartment",
  "apt",
  "unit",
  "suite",
  "building",
  "bldg",
  "floor",
  "fl",
  "block",
  "zipcode",
  "zip",
];

const AboutMore = (props) => {
  const firstName = "Mohit";
  const lastName = "Jatav";
  const fatherName = "Father Name";
  const motherName = "Mother Name";
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formDataLettersCount, setFormDataLettersCount] = useState({
    aboutYourselfText: 0,
    aboutYourFamilyText: 0,
  });

  const [formData, setFormData] = useState({
    aboutYourselfText: "",
    aboutYourFamilyText: "",
  });

  const [formErrors, setFormErrors] = useState({
    aboutYourselfText: "",
    aboutYourFamilyText: "",
  });

  const [touched, setTouched] = useState({
    aboutYourselfText: false,
    aboutYourFamilyText: false,
  });

  const maxLetters = 250;

  let forbiddenNames = [];
  forbiddenNames = forbiddenNames.concat(firstName.split(" "));
  forbiddenNames = forbiddenNames.concat(lastName.split(" "));
  forbiddenNames = forbiddenNames.concat(fatherName.split(" "));
  forbiddenNames = forbiddenNames.concat(motherName.split(" "));
  console.log(forbiddenNames);

  const createForbiddenWordsRegex = (forbiddenWords) => {
    const pattern = `\\b(${forbiddenWords.join("|")})\\b`;
    return new RegExp(pattern, "i");
  };

  const numberWordsRegex = createForbiddenWordsRegex(numberWords);
  const emailWordsRegex = createForbiddenWordsRegex(emailWords);
  const addressWordsRegex = createForbiddenWordsRegex(addressWords);
  const forbiddenNamesRegex = createForbiddenWordsRegex(forbiddenNames);

  useEffect(() => {
    if (touched.aboutYourselfText) {
      if (
        (formData.aboutYourselfText &&
          /[^a-zA-Z\s]/.test(formData.aboutYourselfText)) ||
        numberWordsRegex.test(formData.aboutYourselfText)
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          aboutYourselfText: "Numbers or special characters are not allowed!",
        }));
      } else if (
        formData.aboutYourselfText &&
        emailWordsRegex.test(formData.aboutYourselfText)
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          aboutYourselfText: "Email-related words are not allowed!",
        }));
      } else if (
        formData.aboutYourselfText &&
        addressWordsRegex.test(formData.aboutYourselfText)
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          aboutYourselfText: "Address-related words are not allowed!",
        }));
      } else if (
        formData.aboutYourselfText &&
        forbiddenNamesRegex.test(formData.aboutYourselfText)
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          aboutYourselfText: "Forbidden names are not allowed!",
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          aboutYourselfText: "",
        }));
      }
    }
  }, [formData.aboutYourselfText, touched.aboutYourselfText]);

  useEffect(() => {
    if (touched.aboutYourFamilyText) {
      if (
        (formData.aboutYourFamilyText &&
          /[^a-zA-Z\s]/.test(formData.aboutYourFamilyText)) ||
        numberWordsRegex.test(formData.aboutYourFamilyText)
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          aboutYourFamilyText: "Numbers or special characters are not allowed!",
        }));
      } else if (
        formData.aboutYourFamilyText &&
        emailWordsRegex.test(formData.aboutYourFamilyText)
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          aboutYourFamilyText: "Email-related words are not allowed!",
        }));
      } else if (
        formData.aboutYourFamilyText &&
        addressWordsRegex.test(formData.aboutYourFamilyText)
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          aboutYourFamilyText: "Address-related words are not allowed!",
        }));
      } else if (
        formData.aboutYourFamilyText &&
        forbiddenNamesRegex.test(formData.aboutYourFamilyText)
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          aboutYourFamilyText: "Forbidden names are not allowed!",
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          aboutYourFamilyText: "",
        }));
      }
    }
  }, [formData.aboutYourFamilyText, touched.aboutYourFamilyText]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
    const letterCount = value.trim().length;

    setFormDataLettersCount((prevData) => ({
      ...prevData,
      [id]: letterCount,
    }));
    setTouched((prevTouched) => ({
      ...prevTouched,
      [id]: true,
    }));
  };

  const validateInputs = () => {
    let valid = true;
    const errors = {};

    if (!formData.aboutYourselfText.trim()) {
      errors.aboutYourselfText = "About Yourself is required.";
      valid = false;
    }

    if (!formData.aboutYourFamilyText.trim()) {
      errors.aboutYourFamilyText = "About Your Family is required.";
      valid = false;
    }

    setFormErrors(errors);
    console.log(setFormErrors);
    return valid;
  };

  const handleOnSave = (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);

    if (!validateInputs()) {
      setIsLoading(false);
      return;
    }

    try {
      console.log(formData);
      props.onShowEditSuccess();
    } catch (err) {
      setError(err.message);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <EditProfileLayout onShowSignUp={props.onShowSignUp} editPage="aboutmore">
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="flex justify-start items-center w-full font-bold text-[18px] text-[#FF4061]">
          About More
        </div>
        <div className="flex flex-col md:flex-row justify-center md:justify-between items-center my-2 w-full">
          <div className="flex flex-col justify-center items-start w-full">
            <div className="flex justify-between items-center w-full">
              <label
                htmlFor="aboutYourselfText"
                className="text-[#333333] font-medium mb-1"
              >
                About Yourself:
              </label>
              <span className="text-[14px] text-[#333333]">
                {formDataLettersCount.aboutYourselfText}/{maxLetters}
              </span>
            </div>
            <textarea
              id="aboutYourselfText"
              placeholder="Write about yourself"
              className="focus:outline-[#66B6FF] text-[#858585] border-[2px] border-[#858585] text-[14px] lg:text-16px px-2 py-2 w-full rounded-md min-h-[120px]"
              // onChange={handleAboutYourselfChange}
              onChange={handleChange}
              maxLength={maxLetters}
            />
            {formErrors.aboutYourselfText && (
              <p className="text-[14px] text-[#FF4061] font-medium">
                {formErrors.aboutYourselfText}
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-center md:justify-between items-center my-2 w-full">
          <div className="flex flex-col justify-center items-start w-full">
            <div className="flex justify-between items-center w-full">
              <label
                htmlFor="aboutYourFamilyText"
                className="text-[#333333] font-medium mb-1"
              >
                About Your Family:
              </label>
              <span className="text-[14px] text-[#333333]">
                {formDataLettersCount.aboutYourFamilyText}/{maxLetters}
              </span>
            </div>
            <textarea
              id="aboutYourFamilyText"
              placeholder="Write about your family"
              className="focus:outline-[#66B6FF] text-[#858585] border-[2px] border-[#858585] text-[14px] lg:text-16px px-2 py-2 w-full rounded-md min-h-[120px]"
              // onChange={handleAboutYourFamilyChange}
              onChange={handleChange}
              maxLength={maxLetters}
            />
            {formErrors.aboutYourFamilyText && (
              <p className="text-[14px] text-[#FF4061] font-medium">
                {formErrors.aboutYourFamilyText}
              </p>
            )}
          </div>
        </div>
        <div className="flex justify-start items-center my-5 w-full">
          <Button
            onButtonClick={handleOnSave}
            text={isLoading ? "Loading..." : "Save"}
            className="font-bold w-[130px] h-[50px]"
          >
            {isLoading && <AiOutlineLoading className="animate-spin ml-2" />}
          </Button>
        </div>
      </div>
    </EditProfileLayout>
  );
};

export default AboutMore;
