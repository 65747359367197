import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// Async thunk to fetch profile data
export const fetchProfileData = createAsyncThunk(
  'profile/fetchProfileData',
  async (profileId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/profile/${profileId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to update profile data
export const updateProfileData = createAsyncThunk(
  'profile/updateProfileData',
  async ({ profileId, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/api/profile/${profileId}`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to update personal profile data
export const updatePersonalProfileData = createAsyncThunk(
  'profile/updatePersonalProfileData',
  async ({ profileId, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BACKEND_URL}/`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to update family profile data
export const updateFamilyProfileData = createAsyncThunk(
  'profile/updateFamilyProfileData',
  async ({ profileId, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BACKEND_URL}/`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to update education profile data
export const updateEducationProfileData = createAsyncThunk(
  'profile/updateEducationProfileData',
  async ({ profileId, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BACKEND_URL}/`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to update address profile data
export const updateAddressProfileData = createAsyncThunk(
  'profile/updateAddressProfileData',
  async ({ profileId, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BACKEND_URL}/`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to update social profile data
export const updateSocialProfileData = createAsyncThunk(
  'profile/updateSocialProfileData',
  async ({ profileId, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BACKEND_URL}/`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to update horoscope profile data
export const updateHoroscopeProfileData = createAsyncThunk(
  'profile/updateHoroscopeProfileData',
  async ({ profileId, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BACKEND_URL}/`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to update about more profile data
export const updateAboutMoreProfileData = createAsyncThunk(
  'profile/updateAboutMoreProfileData',
  async ({ profileId, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BACKEND_URL}/`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to update match preferences profile data
export const updateMatchPreferencesProfileData = createAsyncThunk(
  'profile/updateMatchPreferencesProfileData',
  async ({ profileId, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BACKEND_URL}/`, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    profileData: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Handle fetch profile data
    builder
      .addCase(fetchProfileData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProfileData.fulfilled, (state, action) => {
        state.loading = false;
        state.profileData = action.payload;
      })
      .addCase(fetchProfileData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Handle update profile data
    builder
      .addCase(updateProfileData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProfileData.fulfilled, (state, action) => {
        state.loading = false;
        state.profileData = action.payload;
      })
      .addCase(updateProfileData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default profileSlice.reducer;
