import React, { useEffect, useState } from 'react';

import SelectInput from '../../components/UI/SelectInput/SelectInput';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input/Input';
import { AiOutlineLoading } from 'react-icons/ai';

const marital_status = [
  { name: 'Unmarried' },
  { name: 'Divorced' },
  { name: 'Widowed' },
  { name: 'Separated' },
];
const height_inchFt = [{ name: 4 }, { name: 5 }, { name: 6 }, { name: 7 }];
const height_inchIn = [
  { name: 0 },
  { name: 1 },
  { name: 2 },
  { name: 3 },
  { name: 4 },
  { name: 5 },
  { name: 6 },
  { name: 7 },
  { name: 8 },
  { name: 9 },
];
const gender = [{ name: 'Male' }, { name: 'Female' }];
const numOfChildren = [{ name: 0 }, { name: 1 }, { name: 2 }, { name: 3 }];

const SignUpStep2 = ({ onNext, onPrev, weight }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isMarried, setIsMarried] = useState(false);
  const [isChildren, setIsChildren] = useState(false);

  let weight_measure = '';
  const weightArray = [];
  if (weight && weight.length > 0 && weight[0]) {
    const { weight_from, weight_to, measure } = weight[0];
    weight_measure = measure;
    for (let w = weight_from; w <= weight_to; w++) {
      weightArray.push({ name: `${w} ${measure}` });
    }
  }

  const [formData, setFormData] = useState({
    registered_by: '',
    gender: '',
    dob: '',
    height_ft: '',
    height_inch: '',
    weight: '',
    marital_status: '',
    number_of_children: '',
    children_living_with_me: '',
  });

  const [formErrors, setFormErrors] = useState({
    registered_by: '',
    gender: '',
    dob: '',
    height_ft: '',
    height_inch: '',
    weight: '',
    marital_status: '',
    number_of_children: '',
    children_living_with_me: '',
  });

  const [touched, setTouched] = useState({
    registered_by: false,
    gender: false,
    dob: false,
    height_ft: false,
    height_inch: false,
    weight: false,
    marital_status: false,
    number_of_children: false,
    children_living_with_me: false,
  });

  useEffect(() => {
    if (touched.registered_by) {
      if (!formData.registered_by) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          registered_by: 'Please select registered by!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          registered_by: '',
        }));
      }
    }
  }, [formData.registered_by, touched.registered_by]);

  useEffect(() => {
    if (touched.gender) {
      if (!formData.gender) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          gender: 'Please select gender!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          gender: '',
        }));
      }
    }
  }, [formData.gender, touched.gender]);

  useEffect(() => {
    if (touched.dob) {
      const today = new Date();
      const dob = new Date(formData.dob);
      const age = today.getFullYear() - dob.getFullYear();
      const monthDifference = today.getMonth() - dob.getMonth();

      if (
        age < 18 ||
        (age === 18 && monthDifference < 0) ||
        (age === 18 && monthDifference === 0 && today.getDate() < dob.getDate())
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          dob: 'You must be at least 18 years old!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          dob: '',
        }));
      }

      if (!formData.dob) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          dob: 'Please enter date of birth!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          dob: '',
        }));
      }
    }
  }, [formData.dob, touched.dob]);

  useEffect(() => {
    if (touched.height_ft) {
      if (!formData.height_ft) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          height_ft: 'Please select height!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          height_ft: '',
        }));
      }
    }
  }, [formData.height_ft, touched.height_ft]);

  useEffect(() => {
    if (touched.height_inch) {
      if (!formData.height_inch) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          height_inch: 'Please select height!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          height_inch: '',
        }));
      }
    }
  }, [formData.height_inch, touched.height_inch]);

  useEffect(() => {
    if (touched.weight) {
      if (!formData.weight) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          weight: 'Weight is required',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          weight: '',
        }));
      }
    }
  }, [formData.weight, touched.weight]);

  useEffect(() => {
    if (touched.marital_status) {
      if (!formData.marital_status) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          marital_status: 'Please select marital status!',
        }));
      } else {
        if (formData.marital_status === 'Unmarried') {
          setIsMarried(false);
        } else {
          setIsMarried(true);
        }
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          marital_status: '',
        }));
      }
    }
  }, [formData.marital_status, touched.marital_status]);

  useEffect(() => {
    if (touched.number_of_children) {
      if (!formData.number_of_children) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          number_of_children: 'Please select number of children!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          number_of_children: '',
        }));
      }
    }
  }, [formData.number_of_children, touched.number_of_children]);

  useEffect(() => {
    if (touched.children_living_with_me) {
      if (!formData.children_living_with_me) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          children_living_with_me: 'Please select children_living_with_me!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          children_living_with_me: '',
        }));
      }
    }
  }, [formData.children_living_with_me, touched.children_living_with_me]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    // console.log('id, value:', id, value);

    if (id === 'number_of_children') {
      // console.log("value", parseInt(value));
      if (parseInt(value) > 0) {
        setIsChildren(true);
      } else {
        setIsChildren(false);
      }
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
    setTouched((prevTouched) => ({
      ...prevTouched,
      [id]: true,
    }));
  };

  const validateInputs = (data) => {
    console.log(data);

    let valid = true;
    const errors = {
      registered_by: '',
      gender: '',
      dob: '',
      height_ft: '',
      height_inch: '',
      weight: '',
      marital_status: '',
      number_of_children: '',
      children_living_with_me: '',
    };

    if (data.registered_by === '') {
      valid = false;
      errors.registered_by = 'Please select registered by!';
    }
    if (data.gender === '') {
      valid = false;
      errors.gender = 'Please select gender!';
    }
    if (data.dob === '') {
      valid = false;
      errors.dob = 'Please enter date of birth!';
    }
    if (data.height_ft === '') {
      valid = false;
      errors.height_ft = 'Please select!';
    }
    if (data.height_inch === '') {
      valid = false;
      errors.height_inch = 'Please select!';
    }
    if (data.weight === '') {
      valid = false;
      errors.weight = 'Please select weight!';
    }
    if (data.marital_status === '') {
      valid = false;
      errors.marital_status = 'Please select marital status!';
    }
    if (
      data.marital_status &&
      data.marital_status !== 'Unmarried' &&
      data.number_of_children === ''
    ) {
      valid = false;
      errors.number_of_children = 'Please select number of children.';
    }

    if (
      data.marital_status &&
      data.number_of_children &&
      data.number_of_children > 0 &&
      data.children_living_with_me === ''
    ) {
      valid = false;
      errors.children_living_with_me = 'Select an option to proceed.';
    }

    setFormErrors(errors);

    return valid;
  };

  const handleOnNext = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);

    if (!validateInputs(formData)) {
      setIsLoading(false);
      return;
    }

    try {
      formData.weight_measure = weight_measure;
      formData.weight = parseInt(formData.weight.split(' ')[0]);
      formData.children_living_with_me =
        formData.children_living_with_me === 'yes' ? 1 : 0;
      console.log(formData);
      onNext(formData);
      console.log(error);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleOnNext}
      className='flex flex-col justify-start items-start w-full lg:w-[100%] mt-8 h-[400px] md:h-auto'
    >
      <div className='flex flex-col md:flex-row justify-between items-start w-full'>
        <div className='flex justify-center items-center w-full md:w-[48%]'>
          <SelectInput
            id='registered_by'
            name='registered_by'
            label='Registered By:'
            options={[
              { name: 'Self' },
              { name: 'Parent' },
              { name: 'Sibling' },
            ]}
            className='w-full'
            value={formData.registered_by}
            onChange={handleChange}
            error={formErrors.registered_by}
          />
        </div>
        <div className='flex justify-center items-end w-full md:w-[48%]'>
          <SelectInput
            id='gender'
            name='gender'
            label='Gender:'
            options={gender}
            className='w-full'
            value={formData.gender}
            onChange={handleChange}
            error={formErrors.gender}
          />
        </div>
      </div>
      <div className='flex flex-col md:flex-row justify-between items-start w-full'>
        <div className='flex justify-center items-center w-full md:w-[48%]'>
          <Input
            id='dob'
            type='date'
            placeholder='MM/DD/YYYY'
            label='Date of Birth:'
            className='w-full'
            value={formData.dob}
            onChange={handleChange}
            error={formErrors.dob}
          />
        </div>
        <div className='flex justify-center items-end w-full md:w-[48%]'>
          <SelectInput
            id='weight'
            name='weight'
            label='Weight:'
            options={weightArray}
            className='w-full'
            value={formData.weight}
            onChange={handleChange}
            error={formErrors.weight}
          />
        </div>
      </div>
      <div className='flex flex-col md:flex-row justify-between items-start w-full'>
        <div className='flex justify-between items-start w-full md:w-[48%]'>
          <div className='flex justify-between items-center w-[48%]'>
            <SelectInput
              id='height_ft'
              name='height_ft'
              label='Height (Ft):'
              options={height_inchFt}
              className='w-full'
              value={formData.height_ft}
              onChange={handleChange}
              error={formErrors.height_ft}
            />
          </div>
          <div className='flex justify-between items-end w-[48%]'>
            <SelectInput
              id='height_inch'
              name='height_inch'
              label='Height (In):'
              options={height_inchIn}
              className='w-full'
              value={formData.height_inch}
              onChange={handleChange}
              error={formErrors.height_inch}
            />
          </div>
        </div>
        <div className='flex justify-center items-center w-full md:w-[48%]'>
          <SelectInput
            id='marital_status'
            name='marital_status'
            label='Marital Status:'
            options={marital_status}
            className='w-full'
            value={formData.marital_status}
            onChange={handleChange}
            error={formErrors.marital_status}
          />
        </div>
      </div>
      {isMarried && (
        <div className='flex flex-col md:flex-row justify-between items-start w-full'>
          <div className='flex justify-center items-center w-full md:w-[48%]'>
            <SelectInput
              id='number_of_children'
              name='number_of_children'
              label='Number of Children:'
              options={numOfChildren}
              className='w-full'
              value={formData.number_of_children}
              onChange={handleChange}
              error={formErrors.number_of_children}
            />
          </div>
          <div className='flex justify-start items-end w-full md:w-[48%]'>
            {isChildren && (
              <div className='flex flex-col mt-3'>
                <label className='block text-[#333333] mb-2'>
                  Children living with me:
                </label>
                <div className='flex items-center space-x-6'>
                  <label className='inline-flex items-center'>
                    <input
                      id='children_living_with_me'
                      type='radio'
                      name='children_living_with_me'
                      value='yes'
                      className='form-radio h-4 w-4 text-[#FF4061] border-gray-300 focus:ring focus:ring-pink-300'
                      checked={formData.children_living_with_me === 'yes'}
                      onChange={handleChange}
                    />
                    <span className='ml-2 text-gray-700'>Yes</span>
                  </label>
                  <label className='inline-flex items-center'>
                    <input
                      id='children_living_with_me'
                      type='radio'
                      name='children_living_with_me'
                      value='no'
                      className='form-radio h-4 w-4 text-pink-500 border-gray-300 focus:ring focus:ring-pink-300'
                      checked={formData.children_living_with_me === 'no'}
                      onChange={handleChange}
                    />
                    <span className='ml-2 text-gray-700'>No</span>
                  </label>
                </div>
                {formErrors.children_living_with_me && (
                  <div className='text-[14px] text-[#FF4061] font-medium'>
                    {formErrors.children_living_with_me}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      <div className='flex flex-col justify-center items-start w-full mt-10 mb-2'>
        <div className='flex justify-between items-center w-full'>
          <Button
            text='Prev'
            onButtonClick={onPrev}
            className='w-[48%] h-[50px] font-bold text-[24px]'
            bgBtn='bg-[#333333] hover:bg-[#222222]'
          />
          <Button
            text={isLoading ? 'Loading...' : 'Next'}
            className='w-[48%] h-[50px] font-bold text-[24px]'
          >
            {isLoading && <AiOutlineLoading className='animate-spin ml-2' />}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SignUpStep2;
