import { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import AppRoutes from './Routes';
import Signup from './pages/Signup/Signup';
import Signin from './pages/Signin/Signin';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ProfileCompleteStatus from './pages/ProfileForm/ProfileCompleteStatus/ProfileCompleteStatus';
import Footer from './components/Footer/Footer';
import SuccessMessage from './components/UI/SuccessMessage/SuccessMessage';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile } from './redux/features/userSlice';
import Loading from './components/loading/Loading';
import {
  fetchCountry,
  fetchStates,
  fetchCity,
  fetchReligion,
  fetchCommunity,
  fetchSubCommunity,
  fetchWeight,
  fetchMotherTounge,
  fetchQualificationType,
  fetchHighestQualification,
  fetchOccupation,
  fetchAnnualIncome,
} from './redux/features/fieldsSlice';
import { fetchCompanyInfo } from './redux/features/companyMasterSlice';
import { fetchAllImages, fetchImageType } from './redux/features/imageSlice';

function App() {
  const [activeModal, setActiveModal] = useState(null);
  const [successMessageText, setSuccessMessageText] = useState('');
  const dispatch = useDispatch();

  // const loginStatus = useSelector((state) => state.user.status);
  const token = localStorage.getItem('auth_token');
  // console.log('loginStatus: ', loginStatus);

  useEffect(() => {
    if (token) {
      console.log('Token exists:', token);
      dispatch(getUserProfile());
    }
    dispatch(fetchImageType());
    dispatch(fetchCompanyInfo({ typeId: 2 }));
    dispatch(fetchCountry());
    dispatch(fetchStates());
    dispatch(fetchCity());
    dispatch(fetchReligion());
    dispatch(fetchCommunity());
    dispatch(fetchSubCommunity());
    dispatch(fetchWeight());
    dispatch(fetchMotherTounge());
    dispatch(fetchQualificationType());
    dispatch(fetchHighestQualification());
    dispatch(fetchOccupation());
    dispatch(fetchAnnualIncome());
  }, [token, dispatch]);

  const imageTypes = useSelector((state) => state.image.imageTypes);
  useEffect(() => {
    if (token && imageTypes.length > 0) {
      dispatch(fetchAllImages());
    }
  }, [token, dispatch, imageTypes]);

  const showModal = (modal) => setActiveModal(modal);
  const hideModal = () => setActiveModal(null);

  const showSuccessMessageWithText = (text) => {
    setSuccessMessageText(text);
    showModal('successmessage');
  };

  return (
    <>
      {/* <GoogleReCaptchaProvider reCaptchaKey={site_key}> */}
      <Loading />
      <Router>
        {activeModal === 'signup' && (
          <Signup
            onShowSignIn={() => showModal('signin')}
            onHideSignup={hideModal}
            onShowSuccessMessage={showSuccessMessageWithText}
          />
        )}
        {activeModal === 'signin' && (
          <Signin
            onShowSignUp={() => showModal('signup')}
            onHideSignin={hideModal}
            onShowForgotPassword={() => showModal('forgotPassword')}
            onShowSuccessMessage={showSuccessMessageWithText}
          />
        )}
        {activeModal === 'forgotPassword' && (
          <ForgotPassword
            onShowSuccessMessage={showSuccessMessageWithText}
            onHideForgotPassword={hideModal}
          />
        )}

        {activeModal === 'profilecompletestatus' && (
          <ProfileCompleteStatus onHideProfileCompleteStatus={hideModal} />
        )}

        {activeModal === 'successmessage' && (
          <SuccessMessage
            successMessage={successMessageText}
            onHideSuccessMessage={hideModal}
          />
        )}

        <AppRoutes
          onShowBasicInfo={() => showModal('basicinfo')}
          onShowSignUp={() => showModal('signup')}
          onShowSuccessMessage={() => showModal('successmessage')}
        />
        <Footer isLoggedIn={!!token} />
      </Router>
      {/* </GoogleReCaptchaProvider> */}
    </>
  );
}

export default App;
