import Cropper from 'react-easy-crop';
import { MdError, MdOutlineFileUpload } from 'react-icons/md';
import { uploadImage } from '../../../../redux/features/imageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import {
  applyWatermark,
  getCompressedImage,
  getCroppedImg,
  handleCropComplete,
} from '../../../../utils/imageUtils';
import { AiOutlineLoading } from 'react-icons/ai';
import Button from '../../../../components/UI/Button/Button';

const HoroscopeImage = () => {
  const images = useSelector((state) => state.image.horoscopeImages);
  let img = images && images[0] && images[0].url;
  const fullImageUrl = img ? 'https://api.dev.sanroj.com' + img : '';
  // console.log('profilePhotoImages', images);

  useEffect(() => {
    if (fullImageUrl) {
      setHoroscopePicUrl(fullImageUrl);
    }
  }, [fullImageUrl]);

  const dispatch = useDispatch();
  const [isLoding, setIsLoading] = useState(false);
  // const [fileName, setFileName] = useState('');
  const [horoscopePicUrl, setHoroscopePicUrl] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const handleProfilePicChange = async (e) => {
    let file = e.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];

    if (file && allowedTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.onload = () => setSelectedImage(reader.result);
      reader.readAsDataURL(file);
    } else {
      // setFileName('');
      setHoroscopePicUrl('');
    }
    setIsLoading(false);
  };

  const saveCroppedImage = async () => {
    setIsLoading(true);

    try {
      // Step 1: Crop the image
      const croppedImage = await getCroppedImg(
        selectedImage,
        crop,
        croppedAreaPixels
      );

      // Step 2: Apply watermark to the cropped image
      const watermarkedImage = await applyWatermark(croppedImage);

      // Step 3: Compress the image after adding the watermark
      const compressedImage = await getCompressedImage(watermarkedImage);
      // console.log(compressedImage.size / 1024 / 1024);

      // Step 4: Convert to Blob URL and set to state/form data
      const BLOB_URL = URL.createObjectURL(compressedImage);
      setHoroscopePicUrl(BLOB_URL);

      console.log(typeof BLOB_URL, 'dispach');

      // Reset cropper after saving
      dispatch(uploadImage({ image: compressedImage, typeId: 1 }));
      setSelectedImage(null);
    } catch (error) {
      console.error('Error cropping or processing image:', error);
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className='flex flex-col justify-center items-center w-full h-full  md:w-[49%] mt-10'>
        {selectedImage && (
          <div className='flex flex-col justify-center items-center relative py-5 p-4 w-[250px] md:w-[500px] lg:w-[700px] h-[250px] md:h-[400px] bg-white'>
            <div className='cropper-containe'>
              <Cropper
                image={selectedImage}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={(croppedArea, croppedAreaPixels) =>
                  handleCropComplete(croppedAreaPixels, setCroppedAreaPixels)
                }
              />
              <button
                className='bg-green-500 lg:!text-[24px] lg:!font-bold  text-[#FFFFFF] border-2 px-4 py-1 absolute translate-x-[-50%] translate-y-[-50%] bottom-0 rounded-lg font-bold'
                onClick={saveCroppedImage}
              >
                Crop
                {isLoding && <AiOutlineLoading className='animate-spin ml-2' />}
              </button>
            </div>
          </div>
        )}

        <div className='flex gap-3 flex-col justify-center items-center w-[80%] lg:w-[60%]'>
          <p className='flex justify-start items-center border-2 border-[#FF4061aa] bg-[#FF406122] text-[#FF4061] text-[14px] p-2 w-full'>
            <MdError className='mr-2 text-[20px]' />
            Only two images are allowed!
          </p>
          <label
            htmlFor='horoscopeimage'
            className='flex justify-center items-center text-slate-500 font-bold w-[100%] p-4 mb-2 outline-2 outline-slate-300 hover:outline-[#FF406155] outline-dashed rounded-md cursor-pointer bg-slate-50 group'
          >
            <span>Select Horoscope:</span>
            <MdOutlineFileUpload className='text-[25px] ml-2 group-hover:ml-4 duration-100 ' />
          </label>
          <input
            id='horoscopeimage'
            type='file'
            className='hidden'
            accept='image/*'
            onChange={handleProfilePicChange}
          />
          <Button
            text='Upload Horoscope'
            className='w-full md:w-[60%] lg:w-[260px] mt-2 font-medium'
          />
        </div>

        <div className='flex gap-3 flex-col justify-center items-start mb-4 w-[70%] lg:w-[60%]'>
          <p className='flex justify-center items-center text-[#333333] mt-2'>
            {/* <BiSolidFileImage className='text-[20px] text-[#333333] mr-2' /> */}
            <span className='flex justify-center items-center'>
              {/* {fileName && fileName} */}
              {/* <span className='text-[#555] text-[18px]'>...</span> */}
              {horoscopePicUrl && (
                <FaCheckCircle className='mr-2 text-[20px] text-[#4CAF50] ml-2' />
              )}
              Uploaded
            </span>
          </p>
          {/* {fileError && (
            <p className='flex justify-start items-center border-2 border-[#FF4061aa] bg-[#FF406122] text-[#FF4061] text-[14px] p-2 w-full'>
              <MdError className='mr-2 text-[20px]' /> File upload failed!
            </p>
          )} */}

          <div className='flex flex-col justify-center items-center w-full p-3 outline-2 outline-slate-300 outline-dashed rounded-md cursor-pointer bg-slate-50'>
            <img
              src={horoscopePicUrl}
              alt='horoscopeimage'
              className='max-w-full'
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HoroscopeImage;
