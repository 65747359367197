import { useSelector } from 'react-redux';
import DashboardLayout from '../../DashboardLayout';
import PhotoAlbumCard from './PhotoAlbumCard';

const PhotoAlbum = (props) => {
  const albumImages = useSelector((state) => state.image.photoAlbumImages);
  const maxAlbumCount = 4;
  const emptySlots = maxAlbumCount - albumImages.length;
  console.log('albumImages: ', albumImages);

  return (
    <DashboardLayout activePage='photoalbum' onShowSignUp={props.onShowSignUp}>
      <div className='flex flex-col justify-center items-start w-full'>
        <div className='flex justify-start items-center w-full'>
          <p className='font-extrabold text-[26px] text-[#FF4061]'>
            Photo Album
          </p>
        </div>
        <span className='bg-[#CCCCCC] w-full h-[1px] my-3'></span>
        <ul className='flex flex-wrap justify-between items-center w-full'>
          {albumImages.map((image) => (
            <PhotoAlbumCard
              key={image.id}
              imageUrl={'https://api.dev.sanroj.com' + image.url}
              isEditable={true} // Mark existing images as editable
              imageId={image.id}
            />
          ))}

          {/* Render empty cards for new uploads if slots are available */}
          {[...Array(emptySlots)].map((_, index) => (
            <PhotoAlbumCard key={index} isEditable={false} />
          ))}
        </ul>
      </div>
    </DashboardLayout>
  );
};

export default PhotoAlbum;
