import logo from '../../logo.svg';
import Button from '../UI/Button/Button';
import UserIcon from './UserIcon.svg';
import { FaUserGear } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';
import { IoLogOut } from 'react-icons/io5';
import { TbLayoutCardsFilled } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../redux/features/userSlice';
import { useEffect, useState } from 'react';

const Navbar = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [companyLogo, setCompanyLogo] = useState();

  const images = useSelector((state) => state.image.companyLogoImages);
  let img = images && images[0] && images[0].url;
  const fullImageUrl = img ? 'https://api.dev.sanroj.com' + img : '';
  // console.log('companyLogoImages', images);

  useEffect(() => {
    if (fullImageUrl) {
      setCompanyLogo(fullImageUrl);
    }
  }, [fullImageUrl]);

  const loggedInUser = useSelector((state) => state.user.userInfo);
  let companyInfoArray = useSelector((state) => state.companyMaster.info);
  const companyInfo = companyInfoArray?.length > 0 ? companyInfoArray[0] : null;
  const { company_name } = companyInfo || {};
  // console.log('companyInfo: ', companyInfo);

  // console.log('loggedInUser: ', loggedInUser || '');
  // console.log('User: ', loggedInUser?.additional_details?.first_name || '');
  let userName = loggedInUser?.additional_details?.first_name || '';
  userName = userName?.charAt(0).toUpperCase() + userName?.slice(1);

  const handleLogout = async () => {
    try {
      localStorage.removeItem('auth_token');
      await dispatch(logoutUser()).unwrap(); // Wait for the logout to complete
      navigate('/'); // Redirect to login page after logout
    } catch (error) {
      console.error('Error logging out:', error);
    }

    // redirect to login page or home page
  };
  return (
    <header
      className={`${props.className} fixed flex justify-center items-center top-0 left-0 w-[100%] z-20 bg-[#FFFFFF] h-[5rem] lg:h-[6rem] shadow-[0_0_8px_0_rgba(0,0,0,0.25)]`}
    >
      <nav className='w-full mx-auto flex justify-between items-center px-2 md:px-10'>
        {/* <nav className='container mx-auto flex justify-between items-center px-2 lg:px-10'> */}
        {/* <!-- Logo --> */}
        <Link
          to='/'
          className='flex justify-center items-center gap-1 text-[#FF4061] text-lg font-semibold'
        >
          <img src={companyLogo || logo} alt='logo' width={50} />
          {company_name}
        </Link>

        {/* <!-- Sign-in Button --> */}
        <div className='flex justify-center items-center'>
          {/* is below button par mujhe condition lagani hai */}
          {!loggedInUser && (
            <Button
              onButtonClick={props.onShowSignUp}
              text='Sign Up'
              className='lg:!text-[24px] lg:!font-bold mr-2'
            />
          )}
          <div className='relative flex justify-center items-center ml-2 group'>
            {/* is below button par mujhe condition lagani hai */}
            {loggedInUser && (
              <Button
                textColor='#333333'
                text={userName}
                className='!bg-[#FFFFFF]  lg:!font-bold !text-[#FF4061] border-2 border-[#FF4061] px-2'
              >
                <img src={UserIcon} width={30} alt='user-icon' />
                <span className='text-[#ff0000]'>Hello</span>
              </Button>
            )}
            <div className='absolute right-auto top-[100%] w-[90%] text-white bg-[rgba(255,255,255,0.8)] rounded-b-lg hidden group-hover:flex transition border-b-4 border-[#FFFFFF]'>
              <ul className='flex flex-col justify-center items-start w-full pt-8 pb-2'>
                <Link to='/all-matches' className='w-full flex'>
                  <li
                    onClick={props.onShowBasicInfo}
                    className='flex justify-start items-start hover:bg-[#FF4061aa] bg-[#f4f4f4] text-[#333333] hover:text-[#FFFFFF] cursor-pointer w-full px-2 py-3'
                  >
                    <TbLayoutCardsFilled size={26} className='text-[#FF4061]' />
                    <p className='ml-2'>All Matches</p>
                  </li>
                </Link>
                <Link to='/dashboard' className='w-full flex'>
                  <li
                    onClick={props.onShowBasicInfo}
                    className='flex justify-start items-start hover:bg-[#FF4061aa] bg-[#f4f4f4] text-[#333333] hover:text-[#FFFFFF] cursor-pointer w-full px-2 py-3'
                  >
                    <FaUserGear size={26} className='text-[#FF4061]' />
                    <p className='ml-2'>Profile</p>
                  </li>
                </Link>
                <li
                  onClick={handleLogout}
                  className='flex justify-start items-start hover:bg-[#FF4061aa] bg-[#f4f4f4] text-[#333333] hover:text-[#FFFFFF] cursor-pointer w-full px-2 py-3'
                >
                  <IoLogOut size={26} className='text-[#FF4061]' />
                  <p className='ml-2'>Logout</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
