import Button from '../../../../components/UI/Button/Button';
import Input from '../../../../components/UI/Input/Input';
import SelectInput from '../../../../components/UI/SelectInput/SelectInput';
import EditProfileLayout from './EditProfileLayout';
import MultipleHobbiesSelect from '../../../../components/UI/SelectInput/MultipleHobbiesSelect';
import { useEffect, useState } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import PersonalProfileImageProcessing from './PersonalProfileImageProcessing';

const hobbiesOptions = [
  { name: 'Reading' },
  { name: 'Writing' },
  { name: 'Drawing/Painting' },
  { name: 'Photography' },
  { name: 'Music (Listening)' },
  { name: 'Music (Playing Instrument)' },
  { name: 'Singing' },
  { name: 'Dancing' },
  { name: 'Cooking' },
  { name: 'Baking' },
  { name: 'Gardening' },
  { name: 'Traveling' },
  { name: 'Hiking' },
  { name: 'Camping' },
  { name: 'Sports (Playing)' },
  { name: 'Sports (Watching)' },
  { name: 'Fitness/Exercise' },
  { name: 'Yoga' },
  { name: 'Meditation' },
  { name: 'Volunteering' },
  { name: 'Learning New Skills' },
  { name: 'DIY Projects' },
  { name: 'Watching Movies/TV Shows' },
  { name: 'Gaming' },
  { name: 'Collecting (Stamps, Coins, etc.)' },
  { name: 'Fashion/Shopping' },
  { name: 'Foodie/Culinary Exploration' },
  { name: 'Pets/Animal Care' },
  { name: 'Socializing/Networking' },
  { name: 'Outdoor Activities' },
  { name: 'Adventure Sports' },
  { name: 'Art and Crafts' },
  { name: 'Writing Poetry/Prose' },
  { name: 'Fishing' },
  { name: 'Cycling' },
  { name: 'Photography' },
  { name: 'Birdwatching' },
  { name: 'Calligraphy' },
  { name: 'Astrology' },
  { name: 'Board Games/Card Games' },
  { name: 'Reading Literature' },
  { name: 'Cultural Events' },
  { name: 'Languages' },
  { name: 'Technology/Computer Science' },
  { name: 'Entrepreneurship' },
  { name: 'Financial Investments' },
  { name: 'Philanthropy' },
  { name: 'Spirituality' },
  { name: 'Environmental Conservation' },
  { name: 'Politics/Current Affairs' },
  { name: 'History' },
  { name: 'Science' },
  { name: 'Health and Wellness' },
  { name: 'Personal Development' },
  { name: 'Other' },
];
const bloodGroupOptions = [
  { name: 'A+' },
  { name: 'A-' },
  { name: 'B+' },
  { name: 'B-' },
  { name: 'AB+' },
  { name: 'AB-' },
  { name: 'O+' },
  { name: 'O-' },
];

let heightFtData = [{ name: 4 }, { name: 5 }, { name: 6 }, { name: 7 }];
let heightInchData = [
  { name: 0 },
  { name: 1 },
  { name: 2 },
  { name: 3 },
  { name: 4 },
  { name: 5 },
  { name: 6 },
  { name: 7 },
  { name: 8 },
  { name: 9 },
];

const PersonalProfile = (props) => {
  const weightData = useSelector((state) => state.field.weight);
  const user = useSelector((state) => state.user.userInfo);

  const {
    first_name,
    last_name,
    marital_status,
    dob,
    height_ft,
    height_inch,
    weight,
  } = user?.additional_details || {};

  const heightFt = heightFtData.filter(
    (option) => option.name !== parseInt(height_ft)
  );
  const heightInch = heightInchData.filter(
    (option) => option.name !== parseInt(height_inch)
  );

  const weightArray = [];
  if (weightData && weightData[0]) {
    const { weight_from, weight_to, measure } = weightData[0];
    for (let w = weight_from; w <= weight_to; w++) {
      weightArray.push({ name: `${w} ${measure}` });
    }
  }
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    firstName: first_name || '',
    lastName: last_name || '',
    maritalStatus: marital_status || '',
    dateOfBirth: dob,
    timeOfBirth: '',
    placeOfBirth: '',
    heightFt: height_ft || '',
    heightIn: height_inch || '',
    weight: parseInt(weight) || '',
    manglicStatus: '',
    bloodGroup: '',
    hobbiesInterest: [],
    profilePic: null,
  });

  useEffect(() => {
    if (dob || height_ft || height_inch) {
      setFormData((prevData) => ({
        ...prevData,
        dateOfBirth: dob,
        heightFt: height_ft || '',
        heightIn: height_inch || '',
        weight: weight || '',
      }));
    }
  }, [dob, height_ft, height_inch, weight]);

  const [formErrors, setFormErrors] = useState({
    dateOfBirth: '',
    timeOfBirth: '',
    placeOfBirth: '',
    heightFt: '',
    heightIn: '',
    weight: '',
    manglicStatus: '',
    bloodGroup: '',
    hobbiesInterest: '',
    profilePic: '',
  });

  const [touched, setTouched] = useState({
    dateOfBirth: false,
    timeOfBirth: false,
    placeOfBirth: false,
    heightFt: false,
    heightIn: false,
    weight: false,
    manglicStatus: false,
    bloodGroup: false,
    hobbiesInterest: false,
  });

  useEffect(() => {
    if (touched.dateOfBirth) {
      if (!formData.dateOfBirth) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          dateOfBirth: 'Please select date of birth!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          dateOfBirth: '',
        }));
      }
    }
  }, [formData.dateOfBirth, touched.dateOfBirth]);

  useEffect(() => {
    if (touched.heightFt) {
      if (!formData.heightFt) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          heightFt: 'Please select height!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          heightFt: '',
        }));
      }
    }
  }, [formData.heightFt, touched.heightFt]);

  useEffect(() => {
    if (touched.heightIn) {
      console.log('formData.heightIn: ', formData.heightIn);
      if (!formData.heightIn) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          heightIn: 'Please select height!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          heightIn: '',
        }));
      }
    }
  }, [formData.heightIn, touched.heightIn]);

  useEffect(() => {
    if (touched.bloodGroup) {
      if (!formData.bloodGroup) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          bloodGroup: 'Blood group is required',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          bloodGroup: '',
        }));
      }
    }
  }, [formData.bloodGroup, touched.bloodGroup]);

  useEffect(() => {
    if (touched.weight) {
      if (!formData.weight) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          weight: 'Please select weight',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          weight: '',
        }));
      }
    }
  }, [formData.weight, touched.weight]);

  useEffect(() => {
    if (touched.hobbiesInterest) {
      if (!formData.hobbiesInterest) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          hobbiesInterest: 'Please select atleast one hobby!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          hobbiesInterest: '',
        }));
      }
    }
  }, [formData.hobbiesInterest, touched.hobbiesInterest]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    setTouched((prevTouched) => ({
      ...prevTouched,
      [id]: true,
    }));
  };

  const handleHobbiesChange = (hobbies) => {
    setFormData({
      ...formData,
      hobbiesInterest: hobbies,
    });
    setFormErrors({
      ...formErrors,
      hobbiesInterest: '',
    });
  };

  const validateInputs = () => {
    let valid = true;
    const errors = {};

    if (!formData.heightFt) {
      errors.heightFt = 'Height required.';
      valid = false;
    }
    if (!formData.heightIn) {
      errors.heightIn = 'Height required.';
      valid = false;
    }
    if (!formData.weight) {
      errors.weight = 'Weight is required.';
      valid = false;
    }
    if (!formData.bloodGroup) {
      errors.bloodGroup = 'Blood group is required.';
      valid = false;
    }
    if (formData.hobbiesInterest.length === 0) {
      errors.hobbiesInterest = 'At least one hobby is required.';
      valid = false;
    }

    setFormErrors(errors);

    return valid;
  };

  const handleOnSave = () => {
    setIsLoading(true);
    setError(null);

    if (!validateInputs()) {
      setIsLoading(false);
      return;
    }

    try {
      formData.weight = Math.floor(formData.weight?.split(' ')[0]);
      const formDataToSubmit = new FormData();
      // formDataToSubmit.append('firstName', formData.firstName);
      // formDataToSubmit.append('lastName', formData.lastName);
      // formDataToSubmit.append('maritalStatus', formData.maritalStatus);
      formDataToSubmit.append('bloodGroup', formData.bloodGroup);
      formDataToSubmit.append('heightFt', formData.heightFt);
      formDataToSubmit.append('heightIn', formData.heightIn);
      formDataToSubmit.append('weight', formData.weight);
      formDataToSubmit.append('hobbiesInterest', formData.hobbiesInterest);

      setTimeout(() => {
        setIsLoading(false);
        console.log('profile: ', formDataToSubmit);
      }, 1000);
      // Post data to API
    } catch (err) {
      setError(err.message);
      console.log(error);
      setIsLoading(false);
    } finally {
    }
  };

  return (
    <EditProfileLayout onShowSignUp={props.onShowSignUp} editPage='personal'>
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='flex justify-start items-center w-full font-bold text-[18px] text-[#FF4061]'>
          Personal Profile
        </div>
        <PersonalProfileImageProcessing
          formErrors={formErrors}
          setFormData={setFormData}
          setFormErrors={setFormErrors}
        />
        <div className='flex flex-col md:flex-row justify-center md:justify-between items-center w-full '>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <Input
              id='firstName'
              type='text'
              label='First Name:'
              disabled={true}
              placeholder='first name'
              value={formData.firstName}
              // onChange={handleChange}
              className='font-semibold'
              width='w-full'
              error={formErrors.maritalStatus}
            />
          </div>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <Input
              id='lastName'
              type='text'
              label='Last Name:'
              disabled={true}
              value={formData.lastName}
              // onChange={handleChange}
              placeholder='last name'
              className='font-semibold'
              width='w-full'
              error={formErrors.lastName}
            />
          </div>
        </div>
        <div className='flex flex-col md:flex-row justify-center md:justify-between items-center w-full '>
          <div className='flex justify-center items-center w-full md:w-[49%]'>
            <Input
              id='maritalStatus'
              type='text'
              label='Marital Status:'
              disabled={true}
              placeholder='marital status'
              value={formData.maritalStatus}
              // onChange={handleChange}
              className='font-semibold'
              width='w-full'
              error={formErrors.maritalStatus}
            />
          </div>
          <div className='flex justify-between items-center w-full md:w-[49%]'>
            <SelectInput
              id='bloodGroup'
              options={bloodGroupOptions}
              label='Blood Group:'
              value={formData.bloodGroup}
              onChange={handleChange}
              className='border-2 cursor-pointer'
              width='w-full'
              error={formErrors.bloodGroup}
            />
          </div>
        </div>

        <div className='flex flex-col md:flex-row justify-center md:justify-between items-start w-full '>
          <div className='flex justify-between items-start w-full md:w-[49%]'>
            <div className='flex justify-between items-center w-[48%]'>
              <SelectInput
                id='heightFt'
                name='heightFt'
                label='Height (Ft):'
                select={height_ft || 'Ft.'}
                options={heightFt}
                selectValue={dob}
                className='w-full'
                value={formData.heightFt}
                onChange={handleChange}
                error={formErrors.heightFt}
              />
            </div>
            <div className='flex justify-between items-end w-[48%]'>
              <SelectInput
                id='heightIn'
                name='heightIn'
                label='Height (In):'
                select={height_inch || 'In.'}
                options={heightInch}
                className='w-full'
                value={formData.heightIn}
                onChange={handleChange}
                error={formErrors.heightIn}
              />
            </div>
          </div>
          <div className='flex justify-between items-center w-full md:w-[49%]'>
            <SelectInput
              id='weight'
              name='weight'
              label='Weight:'
              options={weightArray}
              className='w-full'
              value={formData.weight}
              select={parseInt(weight) || ''}
              onChange={handleChange}
              error={formErrors.weight}
            />
          </div>
        </div>

        <div className='flex justify-around items-center w-full'>
          <div className='flex justify-cbenter items-center w-full'>
            <MultipleHobbiesSelect
              label='Hobbies:'
              id='hobbies'
              options={hobbiesOptions}
              width='w-full md:w-full lg:w-full'
              value={formData.hobbiesInterest}
              onChange={handleHobbiesChange}
              error={formErrors.hobbiesInterest}
            />
          </div>
        </div>
        <div className='flex justify-start items-center my-5 w-full'>
          <Button
            onButtonClick={handleOnSave}
            text={isLoading ? 'Loading...' : 'Save'}
            className='font-bold w-[130px] h-[50px]'
          >
            {isLoading && <AiOutlineLoading className='animate-spin ml-2' />}
          </Button>
          {formErrors.profilePic && (
            <div className='text-[14px] text-[#FF4061] font-medium ml-3'>
              {formErrors.profilePic}
            </div>
          )}
        </div>
      </div>
    </EditProfileLayout>
  );
};

export default PersonalProfile;
