// import PropTypes from 'prop-types';
// import classNames from 'classnames';

// const SelectInput = ({
//   options,
//   selectType,
//   flexDirection,
//   width,
//   textLabel,
//   colorLabel,
//   required,
//   error,
//   message,
//   messageClassName,
//   messageIcon,
//   label,
//   id,
//   name,
//   value,
//   onChange,
//   className,
//   select,
// }) => {
//   const optionClass = `${width} text-[#858585]`;
//   const labelClass = classNames(
//     'flex justify-center items-center font-medium my-1 relative',
//     textLabel,
//     colorLabel,
//     {
//       'after:content-["*"] after:text-[#FF4061] after:text-[20px] after:absolute after:right-0 after:top-0':
//         required,
//     }
//   );
//   options && console.log('options: ', options);

//   return (
//     <div
//       className={`${flexDirection} flex justify-center items-start my-1 ${width} `}
//     >
//       <label htmlFor={id} className={labelClass}>
//         {label}
//         {message && (
//           <p
//             className={`${messageClassName} flex justify-center items-center ml-8 text-[#75F94C] font-semibold text-[16px]`}
//           >
//             {message}
//             {messageIcon}
//           </p>
//         )}
//       </label>
//       <select
//         id={id}
//         name={name}
//         value={value}
//         onChange={onChange}
//         className={`${className} block w-full h-[50px] px-2 py-2 border-[2px] bg-[#FFFFFF] ${
//           error ? 'border-[#FF4061]' : 'border-[#858585]'
//         } focus:outline-[#66B6FF] rounded-md text-[#858585] text-[16px]`}
//       >
//         <option className={optionClass} value=''>
//           {select}
//         </option>
//         {options.map((option, idx) => (
//           <option
//             key={idx}
//             value={
//               selectType === 'id'
//                 ? option.id
//                 : selectType === 'value'
//                 ? option.value
//                 : option.name
//             }
//             className={optionClass}
//           >
//             {option.name}
//           </option>
//         ))}
//       </select>
//       {error && (
//         <div className='text-[14px] text-[#FF4061] font-medium'>{error}</div>
//       )}
//     </div>
//   );
// };
// SelectInput.defaultProps = {
//   flexDirection: 'flex-col',
//   width: 'w-full',
//   textLabel: 'text-[16px]',
//   colorLabel: 'text-[#333333]',
//   select: 'Select',
// };

// SelectInput.propTypes = {
//   options: PropTypes.array.isRequired,
//   selectType: PropTypes.string,
//   flexDirection: PropTypes.string,
//   width: PropTypes.string,
//   textLabel: PropTypes.string,
//   colorLabel: PropTypes.string,
//   required: PropTypes.bool,
//   error: PropTypes.string,
//   message: PropTypes.string,
//   messageClassName: PropTypes.string,
//   messageIcon: PropTypes.node,
//   label: PropTypes.string.isRequired,
//   id: PropTypes.string.isRequired,
//   name: PropTypes.string.isRequired,
//   value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
//   onChange: PropTypes.func.isRequired,
//   className: PropTypes.string,
//   select: PropTypes.string,
// };
// export default SelectInput;

const SelectInput = (props) => {
  const { options, select, selectValue, selectType } = props;
  // console.log('selectValue: ', selectValue);
  // options && console.log('options: ', options);

  return (
    <div
      className={`${
        props.flexDirection ? props.flexDirection : 'flex-col'
      } flex justify-center items-start my-1 ${
        props.width ? props.width : 'w-full'
      } `}
    >
      <label
        htmlFor={props.id}
        className={`flex justify-center items-center ${
          props.textLabel ? props.textLabel : 'text-[16px]'
        } font-medium ${
          props.colorLabel ? props.colorLabel : 'text-[#333333]'
        } my-1 after:content-['*'] after:text-[#FF4061] after:text-[20px] relative after:absolute after:right-0 after:top-0 ${
          props.required ? '' : 'after:hidden'
        }`}
      >
        {props.label}
        {props.message && (
          <p
            className={`${props.messageClassName} flex justify-center items-center ml-8 text-[#75F94C] font-semibold text-[16px]`}
          >
            {props.message}
            {props.messageIcon}
          </p>
        )}
      </label>
      <select
        id={props.id}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        className={`${
          props.className
        } block w-full h-[50px] px-2 py-2 border-[2px] bg-[#FFFFFF] border-[${
          props.error ? '#FF4061' : '#858585'
        }] focus:outline-[#66B6FF] focus:outline-border-[#66B6FF] rounded-md text-[#858585] text-[16px]`}
      >
        <option
          className={`${props.width} text-[#858585]`}
          value={selectValue | ''}
        >
          {select || 'Select'}
        </option>
        {options &&
          options.map((option, idx) => (
            <option
              key={idx}
              // id={option.id ? option.id : 'does not have id'}

              value={
                selectType === 'id'
                  ? option.id
                  : selectType === 'value'
                  ? option.value
                  : option.name
              }
              className={`${props.width} text-[#858585]`}
            >
              {option.name}
            </option>
          ))}
      </select>
      {props.error && (
        <div className='text-[14px] text-[#FF4061] font-medium'>
          {props.error}
        </div>
      )}
    </div>
  );
};

export default SelectInput;
