import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
// import Cookies from 'js-cookie';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const fetchUsers = createAsyncThunk('user/fetchUsers', async () => {
  const response = await axios.get(BACKEND_URL + '');
  return response.data;
});

export const getUserProfile = createAsyncThunk(
  'user/getProfile',
  async (_, { dispatch }) => {
    try {
      const token = localStorage.getItem('auth_token');
      if (token) {
        const userresponse = await axios.get(BACKEND_URL + '/api/profile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });
        console.log('User data user:', userresponse.data.user);
        const user = userresponse.data.user;
        return user;
      }
      // dispatch(setUser(user));
    } catch (error) {
      console.error('Error fetching user profile:', error);
      throw error;
    }
  }
);

export const loginUser = createAsyncThunk(
  'user/loginUser',
  async (data, { dispatch }) => {
    await axios.get(BACKEND_URL + '/sanctum/csrf-cookie', {
      withCredentials: true,
    });

    const formData = new FormData();
    formData.append('email', data.email);
    formData.append('password', data.password);

    try {
      const response = await axios.post(BACKEND_URL + '/login', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });
      // console.log('Login response:', response.data);
      localStorage.setItem('auth_token', response.data.token);
      const userProfile = await dispatch(getUserProfile()).unwrap();
      return userProfile;
    } catch (error) {
      console.error(
        'Login error:',
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  }
);

export const logoutUser = createAsyncThunk(
  'user/logoutUser',
  async (_, { dispatch }) => {
    try {
      // Backend ko logout request bhejo
      await axios.post(BACKEND_URL + '/logout', {}, { withCredentials: true });

      // localStorage se auth token ko clear karo
      localStorage.removeItem('auth_token');

      // Client-side cookies ko clear karo
      document.cookie =
        'XSRF-TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

      // Redux logout action ko dispatch karo, taaki user state clear ho jaye
      dispatch(logout());

      console.log('Successfully logged out');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  }
);

export const signupUser = createAsyncThunk(
  'user/signupUser',
  async (userData, { rejectWithValue }) => {
    let server_ip = await getIP();
    userData = { ...userData, server_ip };

    try {
      const regResponse = await axios.post(
        BACKEND_URL + '/register',
        userData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true, // Automatically include cookies
        }
      );

      console.log(regResponse);
      if (regResponse.status === 200) {
        console.log('Registration successful');
      }
    } catch (error) {
      console.error('Signup error:', error);
      return rejectWithValue(error.response?.data || 'Signup failed');
    }
  }
);

const getIP = async () => {
  const IP_URL = process.env.REACT_APP_IP_URL;
  try {
    const response = await fetch(IP_URL);
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.log(error);
  }
};

// async function getXSRFToken() {
//   try {
//     await axios.get(BACKEND_URL + '/sanctum/csrf-cookie', {
//       withCredentials: true,
//     });
//     const token = Cookies.get('XSRF-TOKEN');
//     if (!token) throw new Error('XSRF token nahi mila');
//     return token;
//   } catch (error) {
//     console.error('XSRF token fetch karne mein error:', error);
//   }
// }

const userSlice = createSlice({
  name: 'user',
  initialState: {
    users: [],
    status: 'idle',
    userInfo: null, // Add userInfo to track logged-in user
    error: null,
    loading: false,
  },
  reducers: {
    setUser: (state, action) => {
      state.userInfo = action.payload; // Set user info in the state
    },
    logout: (state) => {
      state.userInfo = null; // Clear user info
      state.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Handle loginUser
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.status = 'logging in';
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'logged in';
        state.userInfo = action.payload; // userInfo with response data
        state.loading = false;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = 'login failed';
        state.error = action.error.message;
        state.loading = false;
      })
      // Handle logoutUser
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
        state.status = 'logout pending';
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.status = 'logged out'; // Correct status for successful logout
        state.userInfo = null; // Clear user info on logout
        state.loading = false;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.status = 'logout failed'; // Status for failed logout
        state.error = action.error.message;
        state.loading = false;
      })
      // Handle getUserProfile
      .addCase(getUserProfile.pending, (state, action) => {
        state.loading = true;
        state.userInfo = action.payload; // Set userInfo with profile data
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.userInfo = action.payload; // Set userInfo with profile data
        state.loading = false;
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.userInfo = action.payload; // Set userInfo with profile data
        state.loading = false;
      })
      // Handle signupUser
      .addCase(signupUser.pending, (state) => {
        state.loading = true;
        state.status = 'signing up';
      })
      .addCase(signupUser.fulfilled, (state, action) => {
        state.status = 'sign up successful';
        state.loading = false;
        // Optional: Handle user info or redirect after sign up
      })
      .addCase(signupUser.rejected, (state, action) => {
        state.status = 'sign up failed';
        state.error = action.payload; // Use rejectWithValue for error
        state.loading = false;
      });
  },
});

export const { setUser, logout } = userSlice.actions;
export default userSlice.reducer;
