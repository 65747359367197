import Button from '../../../../components/UI/Button/Button';

import Input from '../../../../components/UI/Input/Input';
import SelectInput from '../../../../components/UI/SelectInput/SelectInput';
import EditProfileLayout from './EditProfileLayout';
import { useEffect, useState } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import HoroscopeImage from './HoroscopeImage';

const manglikStatus = [
  { name: 'Manglik' },
  { name: 'Non-Manglik' },
  { name: 'Partial Manglik' },
  { name: "Don't Know" },
];

const HoroscopeEdit = (props) => {
  const user = useSelector((state) => state.user.userInfo);

  const { dob } = user?.additional_details || {};

  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    dateOfBirth: dob,
    timeOfBirth: '',
    placeOfBirth: '',
    manglicStatus: '',
  });

  useEffect(() => {
    if (dob) {
      setFormData((prevState) => ({
        ...prevState,
        dateOfBirth: dob,
      }));
    }
  }, [dob]);

  const [formErrors, setFormErrors] = useState({
    dateOfBirth: '',
    timeOfBirth: '',
    placeOfBirth: '',
    manglicStatus: '',
  });

  const [touched, setTouched] = useState({
    dateOfBirth: '',
    timeOfBirth: '',
    placeOfBirth: '',
    manglicStatus: '',
  });

  useEffect(() => {
    if (touched.dateOfBirth) {
      if (!formData.dateOfBirth) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          dateOfBirth: 'Please select date of birth!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          dateOfBirth: '',
        }));
      }
    }
  }, [formData.dateOfBirth, touched.dateOfBirth]);

  useEffect(() => {
    if (touched.timeOfBirth) {
      if (!formData.timeOfBirth) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          timeOfBirth: 'Please select time of birth!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          timeOfBirth: '',
        }));
      }
    }
  }, [formData.timeOfBirth, touched.timeOfBirth]);

  useEffect(() => {
    if (touched.placeOfBirth) {
      if (formData.placeOfBirth && !/[^0-9]/.test(formData.placeOfBirth)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          placeOfBirth: 'Only characters are allowed!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          placeOfBirth: '',
        }));
      }
    }
  }, [formData.placeOfBirth, touched.placeOfBirth]);

  useEffect(() => {
    if (touched.manglicStatus) {
      if (!formData.manglicStatus) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          manglicStatus: 'Please select manglic status',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          manglicStatus: '',
        }));
      }
    }
  }, [formData.manglicStatus, touched.manglicStatus]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    setTouched((prevTouched) => ({
      ...prevTouched,
      [id]: true,
    }));
  };

  const validateInputs = () => {
    let valid = true;
    const errors = {};

    if (!formData.dateOfBirth) {
      errors.dateOfBirth = 'Date of birth is required.';
      valid = false;
    }
    if (!formData.timeOfBirth) {
      errors.timeOfBirth = 'Time of birth is required.';
      valid = false;
    }
    if (!formData.placeOfBirth) {
      errors.placeOfBirth = 'Place of birth is required.';
      valid = false;
    } else if (/\d/.test(formData.placeOfBirth)) {
      errors.placeOfBirth = 'Place of birth cannot contain numbers.';
      valid = false;
    }

    if (!formData.manglicStatus) {
      errors.manglicStatus = 'Manglic status is required.';
      valid = false;
    }

    setFormErrors(errors);

    return valid;
  };

  const handleOnSave = () => {
    setIsLoading(true);
    setError(null);

    if (!validateInputs()) {
      setIsLoading(false);
      return;
    }

    try {
      const formDataToSubmit = new FormData();
      formDataToSubmit.append('dateOfBirth', formData.dateOfBirth);
      formDataToSubmit.append('placeOfBirth', formData.placeOfBirth);
      formDataToSubmit.append('timeOfBirth', formData.timeOfBirth);
      formDataToSubmit.append('manglicStatus', formData.manglicStatus);

      console.log(formDataToSubmit);
      setTimeout(() => {
        setIsLoading(false);
        console.log(formData);
      }, 1000);
      // Post data to API
    } catch (err) {
      setError(err.message);
      console.log(error);
      setIsLoading(false);
    } finally {
    }
  };

  return (
    <EditProfileLayout
      onShowSignUp={props.onShowSignUp}
      editPage='horoscopeedit'
    >
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='flex justify-start items-center w-full font-bold text-[18px] text-[#FF4061]'>
          Horoscope
        </div>

        <div className='flex flex-col md:flex-row justify-center md:justify-between items-center md:items-start w-full '>
          <div className='flex flex-col justify-between items-center w-full md:w-[49%]'>
            <Input
              id='dateOfBirth'
              type='date'
              label='Date Of Birth:'
              value={formData.dateOfBirth || ''}
              onChange={handleChange}
              className='font-semibold'
              width='w-full'
              error={formErrors.dateOfBirth}
            />
            <Input
              type='time'
              id='timeOfBirth'
              label='Time Of Birth:'
              placeholder='HH : MM'
              value={formData.timeOfBirth}
              onChange={handleChange}
              className='font-semibold'
              width='w-full'
              error={formErrors.timeOfBirth}
            />
            <Input
              id='placeOfBirth'
              type='text'
              label='Place of Birth:'
              placeholder='Enter place of birth'
              value={formData.placeOfBirth}
              onChange={handleChange}
              className='font-semibold'
              width='w-full'
              error={formErrors.placeOfBirth}
            />
            <SelectInput
              id='manglicStatus'
              options={manglikStatus}
              label='Manglic Status:'
              value={formData.manglicStatus}
              onChange={handleChange}
              className='border-2 cursor-pointer'
              width='w-full'
              error={formErrors.manglicStatus}
            />
            <div className='flex justify-start items-center my-5 w-full'>
              <Button
                onButtonClick={handleOnSave}
                text={isLoading ? 'Loading...' : 'Save'}
                className='font-bold w-[130px] h-[50px]'
              >
                {isLoading && (
                  <AiOutlineLoading className='animate-spin ml-2' />
                )}
              </Button>
            </div>
          </div>
          {/*  */}
          <HoroscopeImage />
          {/*  */}
        </div>
      </div>
    </EditProfileLayout>
  );
};

export default HoroscopeEdit;
