import { Link } from "react-router-dom";
import Button from "../../../../components/UI/Button/Button";
import DashboardLayout from "../../DashboardLayout";
import MatchingSuggestions from "./MatchingSuggestions";
import { ImNotification } from "react-icons/im";
import { GiClick } from "react-icons/gi";
const DashboardPage = (props) => {
  return (
    <DashboardLayout onShowSignUp={props.onShowSignUp} activePage="dashboard">
      <div className="flex flex-col justify-start items-center w-full">
        <div className="flex flex-col gap-4 md:gap-0 md:flex-row justify-between items-center w-full md:w-full self-start px-4 py-2">
          <div className="flex justify-between items-center w-full md:w-[45%]">
            <Link
              to="/searchprofile"
              className="flex justify-start items-center w-[50%]"
            >
              <Button
                text="Search Bri/Gro"
                className="mr-4 font-medium lg:font-semibold text-[14px] lg:text-[16px] w-full md:w-auto"
              />
            </Link>
            <a
              href="#matchingsuggestions"
              className="flex justify-end items-center w-[50%]"
            >
              <Button
                text="New Matches"
                className="ml-4 font-medium lg:font-semibold text-[14px] lg:text-[16px] w-full md:w-auto"
              />
            </a>
          </div>
          <div className="flex justify-center items-center w-full md:w-[45%]">
            <Link to="/edit-profile" className="w-full">
              <div className="bg-[#FE617F] flex justify-between items-center w-full rounded-lg h-[45px]">
                <p className="text-[#FFFFFF] font-medium text-[14px] lg:text-[16px] flex justify-center items-center px-3 w-[65%] h-full">
                  <ImNotification className="mr-2 text-[#FF4061] bg-[#FFFFFF] rounded-full text-[16px] h-[20px] w-[20px]" />
                  Your profile is incomplete.
                </p>
                <Button
                  text="Edit Profile"
                  className="font-medium lg:font-semibold text-[14px] lg:text-[16px] rounded-l-none w-[35%] h-full !m-0"
                  bgBtn="bg-[#FFFFFF]"
                  colorBtn="text-[#FF4061] hover:text-[#FFFFFF] shadow-[2px_0_8px_rgba(0,0,0,0.2)]"
                >
                  <GiClick className="mr-2 text-[#FF4061] bg-[#FFFFFF] rounded-full text-[18px] h-[20px] w-[20px]" />
                </Button>
              </div>
            </Link>
          </div>
        </div>
        <div className="flex justify-center items-center w-full px-4">
          <ul className="flex flex-wrap justify-between items-center w-full">
            <li className="flex flex-col justify-center items-center min-h-[150px] lg:min-h-[232px] w-full md:w-[45%] bg-[#FFFFF4] p-4 rounded-lg shadow-[0_0_12px_rgba(0,0,0,0.1)] my-4">
              <span className="text-[#FF4061] font-bold text-[20px] my-2">
                0
              </span>
              <p className="text-[#333333] text-[18px] font-extrabold my-2">
                Profile View
              </p>
            </li>
            <li className="flex flex-col justify-center items-center min-h-[150px] lg:min-h-[232px] w-full md:w-[45%] bg-[#FFFFF4] p-4 rounded-lg shadow-[0_0_12px_rgba(0,0,0,0.1)] my-4">
              <span className="text-[#FF4061] font-bold text-[20px] my-2">
                0
              </span>
              <p className="text-[#333333] text-[18px] font-extrabold my-2">
                Matches
              </p>
            </li>
            <li className="flex flex-col justify-center items-center min-h-[150px] lg:min-h-[232px] w-full md:w-[45%] bg-[#FFFFF4] p-4 rounded-lg shadow-[0_0_12px_rgba(0,0,0,0.1)] my-4">
              <Link to="/dashboard/latest-request">
                <span className="text-[#FF4061] my-2">
                  <Button text="Check" className="!h-[35px]" />
                </span>
              </Link>
              <p className="text-[#333333] text-[18px] font-extrabold my-2">
                Letest Request
              </p>
            </li>
            <li className="flex flex-col justify-center items-center min-h-[150px] lg:min-h-[232px] w-full md:w-[45%] bg-[#FFFFF4] p-4 rounded-lg shadow-[0_0_12px_rgba(0,0,0,0.1)] my-4">
              <span className="text-[#FF4061] font-bold text-[20px] my-2">
                0
              </span>
              <p className="text-[#333333] text-[18px] font-extrabold my-2">
                Click
              </p>
            </li>
          </ul>
        </div>
      </div>
      <MatchingSuggestions />
    </DashboardLayout>
  );
};
export default DashboardPage;
